import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultAuctionBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_default_auction_banner', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListAuctionBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_list_auction_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAuctionBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_auction_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addAuctionBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/add_auction_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editAuctionBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/edit_auction_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteAuctionBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/delete_auction_banner', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    auctionBannerOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/auction_banner_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImageAuctionBanner(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/cms/upload_image_auction_banner/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
